<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <br />

          <v-container class="py-0" v-if="ErrorMessageTxtFlag == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">{{ ErrorMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-dialog v-model="editRecordPrompt" max-width="85%">
            <member-edit
              pageDescription="Edit Member Details"
              :editRecordPrompt="editRecordPrompt"
              @hideEditRecordPrompt="hideEditRecordPrompt"
              :recordIndex="recordToEdit"
              v-if="editRecordPrompt"
            ></member-edit>
          </v-dialog>

          <v-container>
            <v-row wrap>
              <v-col align="center" cols="12" sm="12" md="12">
                <v-btn
                  @click.prevent="refreshPageData"
                  color="#4285f4"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Refresh
                </v-btn>
                <router-link to="/jcom/subscription-payment/create" v-if="PaymentFlag">
                  <v-btn color="#228b22" tile class="white--text my-3 mr-3">
                    Click here for join JCOM
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0" v-if="JcomTableCount == 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="text-danger">
                  You have not joined any JCOM Tables.
                </h3>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0" v-if="ProgessStart == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <div class="d-flex justify-content-center mb-3">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    label="We are fetching details, please wait..."
                  ></b-spinner>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0" v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">
                  {{ tableData1.length }} records found.
                </h3>
                <v-btn
                  v-if="DownloadFlag"
                  color="#8a2be2"
                  @click.prevent="
                    generateExcel(
                      tableData1,
                      ExcelFields,
                      ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                  >Download</v-btn
                >
              </v-col>
              <p></p>
            </v-row>
            <br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  v-model="selected1"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :show-select="tableOptions1.ShowSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.ActiveStatusTxt="{ item }">
                    <v-chip
                      :color="getActiveStatusColor(item.ActiveStatusTxt)"
                      draggable
                      dark
                      >{{ item.ActiveStatusTxt }}</v-chip
                    >
                  </template>
                  <template v-slot:item.MemberImagePath="{ item }">
                    <img width="100" height="100" :src="item.MemberImagePath" />
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom v-if="item.ReceiptFilePath != ''">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :href="item.ReceiptFilePath"
                          target="_blank"
                          class="mx-2"
                          icon
                          dark
                          small
                          color="#EDBE38"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark> mdi-receipt </v-icon>
                        </v-btn>
                      </template>
                      <span> Receipt Download </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.PreviewFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.EditFlag">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="warning"
                          @click="editAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-document-edit
                        </v-icon>
                      </template>
                      <span> Edit </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>

          <br />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import MemberEdit from "@/view/pages/members/MemberEdit.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    MemberEdit,
  },
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZipDownloadFlag: false,
      ZipFileName: "",

      SelectFlag: true,
      DeleteFlag: false,
      PaymentFlag: false,

      alert: {},

      valid: true,

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: 12,
      JciYearCodeOptions: [],

      MemberTypeRules: [],
      MemberType: 1,
      MemberTypeOptions: [
        { value: 1, text: "Member" },
        { value: 3, text: "SMA Member" },
      ],

      IdCardTypeRules: [],
      IdCardType: 0,
      IdCardTypeOptions: [
        { value: 0, text: "Not issued" },
        { value: 1, text: "Issued" },
      ],

      DownloadMemberPhotosRules: [],
      DownloadMemberPhotos: 0,
      DownloadMemberPhotosOptions: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],

      TableTypeRules: [],
      TableType: "",
      TableTypeOptions: [],

      MemberNameRules: [(v) => !!v || "Name is required"],
      MemberEmailRules: [],
      MemberMobileNoRules: [],
      MemberAddressRules: [],
      MemberCityRules: [],

      totalActiveAwards: 0,
      totalPendingAwards: 0,

      lastDate: "15-JAN-2021 11:59 PM",

      RoleId: "",

      ResultFlag: 0,
      BtnShowFlag: 0,

      NewMembers: [],

      bgColor: "#778899",
      position: "top-right",
      fabActions: [
        /*
        {
          name: 'deleteBtn',
          icon: 'delete',
          color: 'red',
        },
        */
        {
          name: "previewBtn",
          icon: "search",
          color: "orange",
        },
      ],

      singleSelect: true,
      selected1: [],
      selected2: [],
      search: "",
      search2: "",
      loading: true,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordToEdit: "",

      PaymentPaidDate: "",
      menu2: false,

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      TotalMembers: 0,

      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
      JcomTableCount: 0,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.CurrentYearId = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var JcomTableCount = parseInt(tr.JcomTableCount);
        console.log({ JcomTableCount });
        this.JcomTableCount = JcomTableCount;

        if (JcomTableCount > 0) {
          this.searchForm();
        }
      }
    },
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.ResultFlag = flag;
      if (n1 > 0) {
        var filter1 = {
          ActiveStatusTxt: "Active",
        };
        console.log("filter1=" + JSON.stringify(filter1));
        var records1 = rows;
        records1 = records1.filter(function (item) {
          for (var key in filter1) {
            if (item[key] === undefined || item[key] != filter1[key])
              return false;
          }
          return true;
        });
        var n2 = records1.length;
        console.log("n2=" + n2 + ", records1=" + JSON.stringify(records1));

        var filter2 = {
          ActiveStatusTxt: "Pending",
        };
        console.log("filter2=" + JSON.stringify(filter2));
        var records2 = rows;
        records2 = records2.filter(function (item) {
          for (var key in filter2) {
            if (item[key] === undefined || item[key] != filter2[key])
              return false;
          }
          return true;
        });
        var n3 = records2.length;
        console.log("n3=" + n3 + ", records2=" + JSON.stringify(records2));

        this.totalActiveAwards = n2;
        this.totalPendingAwards = n3;
      }
    },
    IdCardType: function () {
      console.log("watch IdCardType");
      var IdCardType = this.IdCardType;
      console.log({ IdCardType });
      this.tableData1 = [];
      this.selected1 = [];
      this.DownloadMemberPhotos = 0;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    rowSelected(tr) {
      console.log("rowSelected called");
      console.log("tr=" + JSON.stringify(tr));
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        // var id = 1;
        console.log("id=" + id);
        // var url = "/lom-single";
        var url = "/member-individual";
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordToEdit = 1;
        this.previewRecordPrompt = true;
        this.selected = tr.AwardsId;
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var id = tr.MemberId;
        console.log({ id });
        this.recordToEdit = id;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);
      if (tableId == 1) {
        var n1 = this.AllDesignation.length;
        console.log("n1=" + n1);
        if (n1 < 5) {
          this.AllDesignation.push({
            year: "",
            level: "",
            position: "",
          });
        }
      }

      if (tableId == 2) {
        var n1 = this.NewMembers.length;
        console.log("n1=" + n1);
        if (n1 < 30) {
          this.NewMembers.push({
            MemberName: "",
            MemberEmail: "",
            MemberMobileNo: "",
            MemberAddress: "",
            MemberCity: "",
          });
        }
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 1) {
          this.AllDesignation.splice(index, 1);
        }
        if (tableId == 2) {
          this.NewMembers.splice(index, 1);
        }
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_table",
        Action: "list_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getFormFieldOptions() {
      console.log("getFormFieldOptions called");
      // this.getContributionTypeIdOptions();
      this.getZoneCodeOptions();
    },
    getContributionTypeIdOptions() {
      console.log("getContributionTypeIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ContributionTypeId";
        var selectbox1_destination = "ContributionTypeIdOptions";
        var selectbox1_url = "api/year-wise-contribution/type-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Jci Year should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getTableTypeOptions() {
      console.log("getTableTypeOptions called");
      var selectbox1_source = "TableType";
      var selectbox1_destination = "TableTypeOptions";
      var selectbox1_url = "api/jcom/table-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm called");

      this.DownloadFlag = false;
      this.ExcelFileName = "";
      this.ZipDownloadFlag = false;
      this.ZipFileName = "";
      this.tableData1 = [];
      this.selected1 = [];

      var server_url = companyConfig.apiURL;

      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/jcom/membership/lists";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", add_url=" + add_url + ", token=" + token
      );

      const thisIns = this;
      var output = "";
      var records = [];
      var table_content = [];
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          table_content = response.data.records;
          console.log("output=" + output + ", flag=" + flag);

          var options = table_content.TableOptions;
          records = table_content.TableData;

          if (flag == 1) {
            thisIns.tableColumns1 = table_content.TableHeader;
            thisIns.tableOptions1 = options;
            thisIns.tableData1 = records;

            thisIns.DownloadFlag = options.DownloadFlag;
            thisIns.ExcelFileName = options.ExcelFileName;
            thisIns.ExcelFields = options.ExcelFields;
            thisIns.ZipDownloadFlag = options.ZipDownloadFlag;
            thisIns.ZipFileName = options.ZipFileName;

            thisIns.toast("success", output);
          } else {
            console.log("error=" + output);
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    updateConfirmAlert() {
      this.resetMessageTxt();

      var selected = this.selected1;
      var n1 = selected.length;
      console.log("n1=" + n1 + ", selected=" + JSON.stringify(selected));

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      if (n1 > 0 && result) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            var upload = {
              Year: this.JciYearCode,
              Members: selected,
            };
            this.updateRecords(upload);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!result) {
          message += "Kindly fill the required fields. ";
        }
        if (n1 == 0) {
          message += "Atleast select one member to update. ";
        }
        this.toast("error", message);
      }
    },
    updateRecords(upload) {
      console.log("updateRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/reports/issued-cards-update";
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.tableData1 = [];
            thisIns.searchForm();
            thisIns.sweetAlert("success", output, true);
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
</style>
